import * as React from "react"
import { graphql } from "gatsby"

import Layout from "./layout2022"
import Seo from "../../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const JournalIndex = ({ data, location }) => {
  return (
    <Layout location={location}>
      <Seo title="220719" />
      <h4 className="font-title-sub text-end">Marine Day in the city</h4>
      <p>Shopping day, ready for autumn with new clothes. Having lunch at cafe, seated on the outside terrace. Summer breezing. An ant crawling on the marble table. My iced coffee's glass is getting sweat. Passing in the middle of July.</p>
      <div className="text-center">
        <StaticImage
          formats={["auto", "webp", "avif"]}
          src="../../../images/journal/2022/sketch_220719_2.jpg"
          width={600}
          quality={95}
        />
      </div>
      <div className="text-end py-5">
        <StaticImage
          formats={["auto", "webp", "avif"]}
          src="../../../images/journal/2022/sketch_220719_1.jpg"
          width={450}
          quality={95}
        />
      </div>
      <div className="text-center py-5">
        <StaticImage
          formats={["auto", "webp", "avif"]}
          src="../../../images/journal/flower_01.png"
          width={300}
          quality={95}
        />
      </div>
      <p>I saw a black dragonfly twice in a day. Next morning, I looked up on google, and it seems called "Haguro Tonbo" in Japanese.</p>
      <p>And it's also called incarnations of god, since its appearance of opening or closing wings looks people praying with putting hands together.</p>
    </Layout>
  )
}

export default JournalIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
